@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .hoverEffect {
    @apply duration-300 cursor-pointer;
  }
  .headerSedenavLi {
    @apply hover:underline underline-offset-2 cursor-pointer text-gray-300 hover:text-white;
  }
}

/* ============= NavLink active Start here ============== */
nav .active li {
  color: black;
  font-weight: 600;
  text-decoration: 1px underline black;
}

.checkoutContainer {
  margin-left: 50px;
}

.checkoutContainer h1 {
  margin-top: 50px;
}

.checkoutForm {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  margin-top: 30px;
  margin-bottom: 20px;
}

.labelValue {
  display: flex;
  margin-bottom: 15px;
}
.labelError{
  border: 1px solid red;
}

.labelValue label {
  width: 120px;
  margin-right: 10px;
}

.SuccesMessage {
  font-size: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.content {
  scale: 0.4;
  margin-bottom: -100px;
  margin-top: -80px;
  justify-content: center;
  display: flex;
  /* top: 10%;
  left: 33%;
  position: absolute; */
}
.circle {
  stroke-dasharray: 1194;
  stroke-dashoffset: 1194;
  animation: dc 1s ease-in-out;
  animation-fill-mode: forwards;
}
.tick {
  stroke-dasharray: 350;
  stroke-dashoffset: 350;
  animation: dt 0.8s ease-out;
  animation-fill-mode: forwards;
  animation-delay: 0.95s;
}
@keyframes dc {
  from {
    stroke-dashoffset: 1194;
  }
  to {
    stroke-dashoffset: 2388;
  }
}
@keyframes dt {
  from {
    stroke-dashoffset: 350;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@media screen and (max-width: 665px) {
  nav .active li {
    color: white;
    font-weight: 600;
    text-decoration: 1px underline white;
  }
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
/* ============= NavLink active End here ================ */
